import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import { signIn } from "../../../actions/auth/authActions";
import { getTimeZone } from "../../../actions/momentActions";
import { createCarrier, updateCarrier } from "../../../actions/carrierActions";
import { getResellers } from "../../../actions/reseller/resellerActions";
import validate from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import Error from "../../Error";
import Success from "../../Success";
import { LABELS as _LABELS } from "../../../language";
import { Alert, CircularProgress } from "@mui/material";
import { searchSafer } from "../../../actions/adminLeadActions";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddAccountForm extends React.Component {
  state = {
    resellers: [],
    searchResult: null,
    searchCarrier: null,
    notFound: false,
  };

  componentDidMount() {
    // const timezone = this.props.getTimeZone();
    // this.props.change("timezone", timezone);
    this.getResellers();
  }

  onCarrierSearch = async (e) => {
    const dotNumber = e.target.value.trim();
    if (!dotNumber) return;

    try {
      this.setState({ searching: true, notFound: false });
      const response = await this.props.searchSafer({ dot: dotNumber });

      if (response && response.company) {
        const company = response.company;
        const mailingAddress = company.mailingAddress || {};
        const physicalAddress = company.physicalAddress || {};

        const searchResult = {
          name: company.name || "",
          contactemail: company.email || "",
          contactmob: company.phone || "",
          contactph: company.phone || "",
          email: company.email || "",
          dot: company.uSDOTNumber || "",
          mcno: company.uSDOTNumber,
          phone: company.mCMXFFNumber || "",
          line1: mailingAddress.street || "",
          line2: "",
          city: mailingAddress.city || "",
          state: mailingAddress.state || "",
          zip: mailingAddress.zip || "",
          country: "US",

          carrier: {
            name: company.name || "",
            line: physicalAddress.street || "",
            city: physicalAddress.city || "",
            state: physicalAddress.state || "",
            zip: physicalAddress.zip || "",
          },
        };

        this.setState({ searchResult, searching: false });
      } else {
        this.setState({ searchResult: null, searching: false, notFound: true });
      }
    } catch (error) {
      console.error("Error fetching carrier information:", error);
      this.setState({ searching: false });
    }
  };
  setSearchData = () => {
    const { searchResult } = this.state;
    if (searchResult) {
      // console.log("searchResult____::",searchResult)
      this.setState({ searchCarrier: searchResult });
    }
  };
  getResellers = async () => {
    if (!this.props.adminAccess) {
      return null;
    }
    try {
      const resellers = await this.props.getResellers();
      if (resellers && Array.isArray(resellers)) {
        this.setState({ resellers });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    const roles = _get(this, "props.profile.roles");
    const resellerId = _get(this, "props.profile.user.resellerId");

    if (roles && resellerId && roles.includes("PARTNER")) {
      values.resellerId = resellerId;
    }

    let response = await this.props.createAccount(values);

    if (response && response.account && response.account._id) {
      this.setState({
        successMessage: "Account is created successfully",
        loading: false,
      });
      this.props.getAccounts();
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = "Something went wrong, please try after some time.";
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const { searchResult, searchCarrier, notFound } = this.state;
    const { adminAccess, resellers } = this.props;
    const selected = this.props.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;

    let initialValues = {
      status: "ACTIVE",
      country: "US",
      city: "",
      dot: "",
      email: "",
      firstName: "",
      lastName: "",
      line1: "",
      line2: "",
      name: "",
      notes: "",
      password: "",
      phone: "",
      state: "",
      timezone: "America/Los_Angeles",
      zip: "",
      ...selected,
    };

    if (searchCarrier) {
      // console.log("searchCarrier_____::",searchCarrier)
      initialValues = { ...initialValues, ...searchCarrier };
    }

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{LABELS.addAccount}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {searchResult && (
          <div
            style={{
              backgroundColor: "#eaf5fb",
              margin: "0 30px",
              borderRadius: "12px",
              padding: "6px",
            }}
          >
            <div style={{ textAlign: "center", fontWeight: "600" }}>
              FOUND ON SAFER.GOV (FMSCSA)
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <div>
                <strong>{searchResult.name}</strong>
                <div>DOT- {searchResult.dot}</div>
                {searchResult.phone && (
                  <div> Contact Phone:- {searchResult.contactmob}</div>
                )}
                {searchResult.email && <div>Email:- {searchResult.email}</div>}
                <div>
                  Address: {searchResult.line1} {searchResult.city}{" "}
                  {searchResult.state}-{searchResult.zip}
                </div>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.setSearchData}
                >
                  {"USE THIS CARRIER"}
                </Button>
              </div>
            </div>
          </div>
        )}
        {notFound && ( // Render not found message
          <Alert severity="error" style={{ marginBottom: 10 }}>
            Data not found for this DOT number.
          </Alert>
        )}
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              const ALL_STATES =
                values.country === "CA"
                  ? constants.CA_STATES
                  : constants.US_STATES;

              return (
                <Form style={{ padding: "20px 10px" }}>
                  {this.state.searching && (
                    <Grid md={12}>
                      <Alert
                        color="primary"
                        icon={false}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress color="primary" />
                        <div>
                          Searching carrier information on
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://safer.fmcsa.dot.gov/CompanySnapshot.aspx`}
                          >
                            {" https://safer.fmcsa.dot.gov "}
                          </a>
                          .
                        </div>
                      </Alert>
                    </Grid>
                  )}
                  <Grid container spacing={3}>
                    <SelectBox
                      md={adminAccess ? 1 : 2}
                      name="status"
                      label={LABELS.status}
                      disabled={true}
                      items={constants.ALL_STATUS_OPTIONS}
                    />
                    <SelectBox
                      md={adminAccess ? 1 : 2}
                      name="country"
                      label={LABELS.country}
                      selLabel={"id"}
                      items={constants.COUNTRIES}
                    />
                    {adminAccess && (
                      <SelectBox
                        md={3}
                        selValue={"_id"}
                        name="resellerId"
                        label={LABELS.reseller}
                        items={resellers}
                      />
                    )}
                    <InputBox md={3} name="name" label="Carrier name" />
                    <InputBox
                      md={2}
                      name="dot"
                      label="DOT"
                      onBlur={this.onCarrierSearch}
                    />
                    <SelectBox
                      md={adminAccess ? 2 : 3}
                      name="timezone"
                      label={LABELS.timezone}
                      items={constants.TIME_ZONES}
                    />

                    <InputBox name="line1" label={LABELS.address_line1} />
                    <InputBox md={2} name="line2" label={LABELS.suit_bldg} />
                    <InputBox md={2} name="city" label={LABELS.city} />
                    <SelectBox
                      md={2}
                      name="state"
                      label={LABELS.state}
                      selLabel={"id"}
                      selLabelThree={"name"}
                      items={ALL_STATES}
                    />

                    <InputBox md={2} name="zip" label={LABELS.zip} />
                    <InputBox name="firstName" label={LABELS.firstName} />
                    <InputBox name="lastName" label={LABELS.lastName} />
                    <InputBox name="contactmob" label={LABELS.phone} />
                    <InputBox name="email" label={LABELS.email} />
                    <InputBox name="password" label={LABELS.password} />
                    <InputBox name="notes" label={LABELS.note} />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {loading ? "Saving..." : "SAVE"} */}
                      {loading ? LABELS.saving : LABELS.save}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABELS.close}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: _get(state, "authData.profile", {}),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
    adminAccess: _get(state, "authData.profile.adminAccess", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signIn,
      createCarrier,
      updateCarrier,
      getTimeZone,
      getResellers,
      searchSafer,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAccountForm))
);
